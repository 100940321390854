
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import RichTextViewer from '@/components/ui/RichText/ui/RichTextViewer.vue';
import { multicontentSessionGet } from '@/services/api/multi-content';
import { parseMulticontentBody } from '@/components/multicontent/composables/useMultiContentBody';
import { LessonContentItemRichText, LessonContentItemType } from '@/domains/multicontent';
import parseJsonContent from '@/components/ui/RichText/helpers/parseJsonContent';

export default defineComponent({
  name: 'MulticontentRichTextExternal',
  components: {
    RichTextViewer,
  },
  setup() {
    const route = useRoute();
    const item = ref<LessonContentItemRichText>();
    const value = computed(() => parseJsonContent(item.value?.value));

    const postMessage = (event: 'success' | 'error', message?: string): void => {
      // @ts-ignore
      // https://diamantidis.github.io/2021/10/17/two-way-communication-between-flutter-webview-and-a-web-page
      const { messageChannel } = window;
      if (!messageChannel) {
        console.error('MessageChannel not found!');
        return;
      }

      messageChannel.postMessage(event, message);
    };
    const fetch = async () => {
      try {
        const { richTextId } = route.params;
        const playerSessionId = Number(route.params.playerSessionId);
        const { body: contentBody } = await multicontentSessionGet({ playerSessionId });
        const lessonContent = parseMulticontentBody(contentBody);
        const found = lessonContent.find((c) => {
          return c.type === LessonContentItemType.RICH_TEXT && c.id === richTextId;
        });
        if (!found) {
          throw new Error(`RichTextContent with id: ${richTextId} not found!`);
        }
        item.value = found as LessonContentItemRichText;
        postMessage('success');
      } catch (e) {
        console.error(e);
        postMessage('error', (e as Error).toString());
      }
    };

    onMounted(async () => {
      await fetch();
    });

    return {
      value,
    };
  },
});
